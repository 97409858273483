import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/programas.css";
import { fetchProgramacao, fetchConfiguracoes } from "../../utils/dados";

interface ProgramasData {
    id: number;
    slug: string;
    programa: string;
    imagem: string;
    h_seg: string;
    h_ter: string;
    h_qua: string;
    h_qui: string;
    h_sex: string;
    h_sab: string;
    h_dom: string;
}

interface ARadioData {
    link_admin: string;
}

const Programas = () => {
    const [ProgramasData, setProgramasData] = useState<ProgramasData[]>([]);
    const [selectedDay, setSelectedDay] = useState(""); // Estado para armazenar o dia selecionado
    const [configuracoes, setConfiguracoes] = useState<ARadioData>();

    const getDayOfWeek = () => {
        const daysOfWeek = ["dom", "seg", "ter", "qua", "qui", "sex", "sab"];
        const today = new Date().getDay(); // Obtém o dia da semana (0 a 6, onde 0 é domingo)
        return daysOfWeek[today];
    };

    const fetchInfoByDay = async (day: string) => {
        const programas = await fetchProgramacao(day);
        setProgramasData(programas);
    };

    useEffect(() => {
        // Defina o valor padrão como o dia de hoje (por exemplo, 'seg')
        const today = getDayOfWeek(); // Implemente a função getDayOfWeek() para obter o dia atual
        setSelectedDay(today);

        // Busque informações com base no dia atual
        fetchInfoByDay(today);

        async function fetchData() {
            const configuracoes = await fetchConfiguracoes();
            setConfiguracoes(configuracoes);
        }

        fetchData();
    }, []);

    // Manipule a mudança no valor do select
    const handleDayChange = (event: any) => {
        const newDay = event.target.value;
        setSelectedDay(newDay);

        // Busque informações com base no novo dia selecionado
        fetchInfoByDay(newDay);
    };

    return (
        <div>
            <div>
                {/* Campo de seleção para escolher o dia */}
                <select id="daySelect" value={selectedDay} onChange={handleDayChange}>
                    <option value="seg">Segunda-feira</option>
                    <option value="ter">Terça-feira</option>
                    <option value="qua">Quarta-feira</option>
                    <option value="qui">Quinta-feira</option>
                    <option value="sex">Sexta-feira</option>
                    <option value="sab">Sábado</option>
                    <option value="dom">Domingo</option>
                </select>
            </div>
            <div className="programa-container">
                {ProgramasData.map((programa) => (
                    <div key={programa.id} className="programa-card">
                        <Link className="programa-card-link" key={programa.id} to={``}></Link>
                        <div
                            className="card-image"
                            style={{ backgroundImage: `url(${configuracoes?.link_admin}/uploads/${programa.imagem.replace(/&quot;/g, '"')})` }}
                        ></div>
                        <h3>{programa.programa}</h3>
                        <p>
                            {(() => {
                                switch (selectedDay) {
                                    case "seg":
                                        return programa.h_seg;
                                    case "ter":
                                        return programa.h_ter;
                                    case "qua":
                                        return programa.h_qua;
                                    case "qui":
                                        return programa.h_qui;
                                    case "sex":
                                        return programa.h_sex;
                                    case "sab":
                                        return programa.h_sab;
                                    case "dom":
                                        return programa.h_dom;
                                    default:
                                        return "Horário não disponível";
                                }
                            })()}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Programas;
