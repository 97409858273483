import React, { useEffect, useState } from "react";
import Layout from "./../Layout";
import { useParams } from "react-router-dom";
import moment from "moment";
import { fetchNoticia } from "../../utils/dados";
import "./../../assets/css/noticias.css";
import Header from "../features/Header";

interface NewsData {
    id: number;
    slug: string;
    titulo: string;
    noticia: any;
    data: any;
    frase_destaque: string;
    imagem_destaque: string;
}

const NoticiaPageInterna: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const [newsData, setNewsData] = useState<NewsData>();

    const dataFormatada = moment(newsData?.data, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm");

    useEffect(() => {
        async function fetchData() {
            const noticia = await fetchNoticia(slug);
            setNewsData(noticia);
        }

        fetchData();
    }, [slug]);

    return (
        <>
            <Header
                title={newsData?.titulo ? newsData?.titulo : ""}
                description={newsData?.frase_destaque ? newsData?.frase_destaque : ""}
                image={newsData?.imagem_destaque ? newsData?.imagem_destaque : ""}
                type="article"
                type_twitter="summary"
                url={`noticias/${newsData?.slug}`}
            />
            <Layout>
                <div className="container">
                    <div className="content-container">
                        <div className="news-container-interna">
                            <div className="news-interna-header">
                                <h1>{newsData?.titulo}</h1>
                                <h2> {newsData?.frase_destaque} </h2>
                                <span className="data">{dataFormatada}</span>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: newsData?.noticia }}></div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default NoticiaPageInterna;
