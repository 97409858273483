import React, { useState, useEffect } from "react";
import { fetchConfiguracoes } from "../utils/dados";
import "./../assets/css/styles.css";
import "./../assets/css/footer.css";

interface ARadioData {
    titulo: string;
    descricao_resumida: string;
    link_admin: string;
    logo: string;
    googlemaps: any;
}

const Footer: React.FC = () => {
    const [configuracoes, setConfiguracoes] = useState<ARadioData>();

    useEffect(() => {
        async function fetchData() {
            const configuracoes = await fetchConfiguracoes();
            setConfiguracoes(configuracoes);
        }

        fetchData();

        // Carregar o script do socket.io
        const script = document.createElement("script");
        script.src = "https://cdn.socket.io/4.5.4/socket.io.min.js";
        script.async = true;
        document.body.appendChild(script);

        // Inicializar o socket.io
        script.onload = () => {
            let socket = io();
        };

        // Limpar o script quando o componente for desmontado
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <footer>
            <div className="container container-footer">
                <div className="container-logo">
                    <img src={`${configuracoes?.link_admin}/uploads/${configuracoes?.logo}`} alt={`${configuracoes?.titulo}`} style={{ maxWidth: "150px" }} />
                    <h3 className="titulo_footer">{configuracoes?.titulo}</h3>
                    <span className="descricao_footer">{configuracoes?.descricao_resumida}</span>
                </div>
                <div className="container-map">
                    <div className="map" dangerouslySetInnerHTML={{ __html: configuracoes?.googlemaps }} />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
