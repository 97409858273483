import React, { useEffect, useState } from "react";
import Layout from "./../Layout";
import { fetchConfiguracoes } from "../../utils/dados";
import "./../../assets/css/noticias.css";

interface ARadioData {
    descricao: any;
    titulo: string;
}

const ARadioPage: React.FC = () => {
    const [configuracoes, setConfiguracoes] = useState<ARadioData>();

    useEffect(() => {
        async function fetchData() {
            const configuracoes = await fetchConfiguracoes();
            setConfiguracoes(configuracoes);
        }

        fetchData();
    }, []);

    return (
        <Layout>
            <div className="container">
                <div className="content-container">
                    <div className="news-container-interna">
                        <div className="news-interna-header">
                            <h1>{configuracoes?.titulo}</h1>
                            <h2> {"Saiba mais sobre nós"} </h2>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: configuracoes?.descricao }}></div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ARadioPage;
