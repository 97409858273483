import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import { fetchConfiguracoes } from "../utils/dados";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter, faYoutube, faWhatsapp, faLinkedin, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faChevronRight, faPhone } from "@fortawesome/free-solid-svg-icons";

const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    background-color: #3b0251;
    color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
`;

interface ARadioData {
    descricao: string;
    telefones: string;
    email: string;
    endereco: string;
    numero: string;
    bairro: string;
    cidade: string;
    estado: string;
    cep: string;
    link_facebook: string;
    link_instagram: string;
    link_twitter: string;
    link_youtube: string;
    link_whatsapp: string;
    link_linkedin: string;
    link_google: string;
    logo: string;
    link_admin: string;
}

const Header: React.FC = () => {
    const [configuracoes, setConfiguracoes] = useState<ARadioData>();

    useEffect(() => {
        async function fetchData() {
            const configuracoes = await fetchConfiguracoes();
            setConfiguracoes(configuracoes);
        }

        fetchData();
    }, []);

    return (
        <HeaderContainer>
            <div className="logo-container">
                <a href={"/"}>
                    <img src={`${configuracoes?.link_admin}/uploads/${configuracoes?.logo}`} alt="logo" />
                </a>
            </div>
            <div className="container-menu-header">
                <div className="container-redes-header">
                    <a href={"/a-radio"} className="link-header">
                        <FontAwesomeIcon icon={faChevronRight} />
                        Política de Privacidade
                    </a>
                    <a href={`tel:${configuracoes?.telefones}`} className="link-header">
                        <FontAwesomeIcon icon={faPhone} />
                        {configuracoes?.telefones}
                    </a>
                    {configuracoes?.link_facebook && (
                        <a href={configuracoes.link_facebook} className="icone-social-container facebook-bg" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                    )}
                    {configuracoes?.link_instagram && (
                        <a href={configuracoes.link_instagram} className="icone-social-container instagram-bg" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    )}
                    {configuracoes?.link_twitter && (
                        <a href={configuracoes.link_twitter} className="icone-social-container twitter-bg" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    )}
                    {configuracoes?.link_youtube && (
                        <a href={configuracoes.link_youtube} className="icone-social-container youtube-bg" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>
                    )}
                    {configuracoes?.link_whatsapp && (
                        <a href={configuracoes.link_whatsapp} className="icone-social-container whatsapp-bg" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faWhatsapp} />
                        </a>
                    )}
                    {configuracoes?.link_linkedin && (
                        <a href={configuracoes.link_linkedin} className="icone-social-container linkedin-bg" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    )}
                    {configuracoes?.link_google && (
                        <a href={configuracoes.link_google} className="icone-social-container google-bg" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faGoogle} />
                        </a>
                    )}
                </div>
                <Navbar />
            </div>
        </HeaderContainer>
    );
};

export default Header;
